





































































































import { Vue, Component } from 'vue-property-decorator';
import { mapState } from 'vuex';

import LanguageSwitcher from '~/components/partials/LanguageSwitcher.vue';

@Component({
  components: { LanguageSwitcher },
  computed: {
    ...mapState('lang', {
      locale: 'locale',
    }),
    ...mapState([
      'sitePartners',
    ]),
  },
})
export default class Footer extends Vue {
  sitePartners: string[];
  locale: string;

  /* Returns reactive array of menus elements */
  get menus () {
    return [
      {
        name: this.$t('footer.onPisteMenu.name'),
        links: [
          {
            name: this.$t('footer.onPisteMenu.links.about'),
            link: '/about',
            isExternal: false,
          },
          {
            name: this.$t('footer.onPisteMenu.links.explore'),
            link: '/explorer',
            isExternal: false,
          },
          // {
          //   name: this.$t('footer.onPisteMenu.links.blog'),
          //   link: '/blog',
          //   isExternal: false,
          // },
          {
            name: this.$t('footer.onPisteMenu.links.download'),
            link: '/onpiste-app',
            isExternal: false,
          },
          {
            name: this.$t('footer.onPisteMenu.links.contact'),
            link: '/contact',
            isExternal: false,
          },
          // {
          //   name: this.$t('footer.onPisteMenu.links.faq'),
          //   link: '/faq',
          //   isExternal: false,
          // },
          {
            name: this.$t('footer.onPisteMenu.links.respect'),
            link: '/respect',
            isExternal: false,
          },
        ],
      },
      {
        name: this.$t('footer.socialsMenu.name'),
        links: [
          {
            name: this.$t('footer.socialsMenu.facebook'),
            link: 'https://www.facebook.com/app.onpiste',
            isExternal: true,
          },
          {
            name: this.$t('footer.socialsMenu.instagram'),
            link: 'https://www.instagram.com/onpiste.app/',
            isExternal: true,
          },
        ],
      },
      {
        name: this.$t('footer.settingsMenu.name'),
        links: [
          {
            name: this.$t('footer.settingsMenu.links.cookiesPolicy'),
            link: '/cookie-policy',
            isExternal: false,
          },
          {
            name: this.$t('footer.settingsMenu.links.privacy'),
            link: '/privacy-policy',
            isExternal: false,
          },
          {
            name: this.$t('footer.settingsMenu.links.terms'),
            link: '/terms-and-conditions',
            isExternal: false,
          },
          {
            name: this.$t('footer.settingsMenu.links.conditions'),
            link: '/conditions-of-use',
            isExternal: false,
          },
          {
            name: this.$t('footer.settingsMenu.links.cookies'),
            link: 'javascript:openAxeptioCookies()',
            isExternal: true,
          },
        ],
      },
    ];
  };

  mounted () {
    // Will fetch footer logos of partners if aren't available yet in store
    if (!this.sitePartners?.length) this.$store.dispatch('getSitePartners');
  }
}
